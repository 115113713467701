<template>
  <div class="flex items-center justify-between gap-x-6 border-b border-gray-200 bg-white px-4 py-4 shadow-sm sm:px-6">
    <button class="-m-2.5 p-2.5 text-gray-700 lg:hidden" type="button" @click="showSidebar">
      <span class="sr-only">{{ $t('navigation.openSidebar') }}</span>
      <Bars3Icon aria-hidden="true" class="h-6 w-6" />
    </button>

    <NuxtLink href="/documents?filter[status]=pending">
      <VeezuLogo class="h-4 w-auto" />
    </NuxtLink>

    <button class="-m-2.5 p-2.5 text-gray-700 lg:hidden" type="button" @click="$emit('showNotifications')">
      <span class="sr-only">{{ $t('navigation.viewNotifications') }}</span>
      <BellIcon aria-hidden="true" class="h-6 w-6" />
    </button>
  </div>

  <Teleport to="body">
    <TransitionRoot :show="sidebarOpen" as="template">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="hideSidebar">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button class="-m-2.5 p-2.5" type="button" @click="hideSidebar">
                    <span class="sr-only">{{ $t('navigation.closeSidebar') }}</span>
                    <XMarkIcon aria-hidden="true" class="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <TheSidebar />
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import TheSidebar from '~/components/TheSidebar.vue'

defineEmits<{
  showNotifications: []
}>()

const sidebarOpen = ref(false)

function showSidebar() {
  sidebarOpen.value = true
}

function hideSidebar() {
  sidebarOpen.value = false
}

watch(() => useRoute().path, hideSidebar)
</script>
